import React from 'react';
import Input from '../Input';
import Continue from '../Continue';
import history from '../../history';
import { setData } from '../../actions';
import meetsInfoRequirements from '../../meets-info-requirements';
import DeliveryCalendar from '../DeliveryCalendar';
import Modal from '../Modal';
import Alert from '../Alert';
import request from 'arcdynamic-request';

export default React.createClass({
	_handleSubmit(e) {
		const { data, requestType } = this.props;

		e.preventDefault();

		if (data && meetsInfoRequirements(requestType, data.info)) {
			history.push(arc.path.base+requestType+'/products');
		}
	},
	_setInfo(prop, value) {
		const data = this.props.data ? {...this.props.data} : {};
		
		data.info = data.info ? {...data.info} : {};
		data.info[prop] = value;
		setData(this.props.requestType, data);
	},
	_handleChange(e) {
		this._setInfo(e.currentTarget.name, e.currentTarget.value);
	},
	_setDate(deliveryDate) {
		this._setInfo('deliveryDate', deliveryDate);
		this.setState({showCalendar: false});
	},
	_showCalendar() {
		this.setState({showCalendar: true});
	},
	componentDidMount() {
		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.utils.Date.get',
			}),
			request(arc.path.api, {
				service: 'arcimedes',
				action: 'open.datasource.table.Data.getData',
				params: ['code', 'DISABLED_SAMPLE_SHIPPING_DATES', {
					filter: [
						{
							code: 'FIRST_DATE',
							text: '',
							type: 'notNull',
						},
						{
							code: 'LAST_DATE',
							text: '',
							type: 'notNull',
						},
					],
					limit: {
						count: 999,
						offset: 0,
					},
				}],
			}, {
				expires: 1000*60*60,
			}),
		]).then(values => {
			this.setState({
				currentDate: values[0].data,
				disabledDates: values[1].data,
			});
		});
	},
	getInitialState() {
		return {
			showCalendar: false,
			currentDate: false,
		};
	},
	render() {
		const info = this.props.data && this.props.data.info || {};

		return (
			<div className='SamplesInfo'>
				<h2>Samples Request Information</h2>
				<form onSubmit={this._handleSubmit}>
					<Input required name='requestReason' value={info.requestReason} onChange={this._handleChange} type='text' label='Purpose of this sample request:'/>
					<Input required name='deliveryDate' onClick={this._showCalendar} value={info.deliveryDate} autoComplete="off" type='text' label='Date required in customer’s hands:'/>
					{
						this.state.showCalendar ? (
							<Modal onClose={()=> this.setState({showCalendar: false})}>
								<div style={{width: '100vw', maxWidth: '400px'}}>
									<div>Date required in customer’s hands:</div>
									<Alert type='warn'>Please provide as much lead-time as possible. Samples are delivered Tuesday - Thursday only. After your request is approved, you must allow a minimum of 48 hours for delivery. Keep in mind, not all products are available at all times.</Alert>
									<DeliveryCalendar currentDate={this.state.currentDate} disabledDates={this.state.disabledDates} setDate={this._setDate} deliveryDate={info.deliveryDate}/>
								</div>
							</Modal>
						) : null
					}

					<Continue isMuted={!meetsInfoRequirements(this.props.requestType, info)}/>
				</form>
			</div>
		);
	},
});
