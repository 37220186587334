import React from 'react';
import Continue from '../Continue';
import history from '../../history';

export default React.createClass({
	_handleSubmit(e) {
		const { requestType } = this.props;
		e.preventDefault();
		history.push(arc.path.base+requestType+'/products');
	},
	render() {
		return (
			<div className='POSInfo'>
				<h2>Point Of Sale</h2>
				<form onSubmit={this._handleSubmit}>
					<p>As of January 2022, David’s Cookies has transitioned POS materials from hard copies to digital copies only. The digital copy will be able for you on the next page to download and print locally.
Keep in mind that digital copies can always be downloaded or shared for everyone from our flipping book: <a target='_blank' href='https://davidscookies.cld.bz/David-s-Cookies-Product-Catalog'>Click Here to view</a>.</p>
					<Continue/>
				</form>
			</div>
		);
	},
});
