import React from 'react';
import Select from '../Select';
import Input from '../Input';
import Continue from '../Continue';
import history from '../../history';
import Modal from '../Modal';
import { setData } from '../../actions';
import meetsInfoRequirements from '../../meets-info-requirements';

export default React.createClass({
	_handleSubmit(e) {
		const { data, requestType } = this.props;

		e.preventDefault();

		if (data && meetsInfoRequirements(requestType, data.info, this.props.userType)) {
			history.push(arc.path.base+requestType+'/products');
		}
	},
	_handleChange(e) {
		const key = e.currentTarget.name;
		const value = e.currentTarget.value;
		const data = {...this.props.data};
		
		data.info = {...data.info};
		data.info[key] = value;
		setData(this.props.requestType, data);
	},
	_handleQuestion(e) {
		const key = e.currentTarget.name;
		const value = e.currentTarget.value;
		const data = {...this.props.data};

		data.info = {...data.info};

		if (value === 'No') {
			delete data.info.distributors;
		}

		data.info[key] = value;

		setData(this.props.requestType, data);

		if (value === 'No' && (this.props.userType !== 'internal' && this.props.userType !== 'broker' && this.props.userType !== 'limited broker')) {
			this.setState({ modal: true });
		}
	},
	_handleName(e) {
		const key = e.currentTarget.name;
		const value = e.currentTarget.value;
		const data = {...this.props.data};

		data.info = {};
		data.info[key] = value;
		setData(this.props.requestType, data);
	},
	_setInfo(prop, value) {
		const data = this.props.data ? {...this.props.data} : {};
		
		data.info = data.info ? {...data.info} : {};
		data.info[prop] = value;
		setData(this.props.requestType, data);
	},
	getInitialState() {
		return {
			modal: false,
		};
	},
	render() {
		const info = this.props.data && this.props.data.info || {};
		const { userType } = this.props;

		return (
			<div className='MerchInfo'>
				<h2>{this.props.heading}</h2>
				<form onSubmit={this._handleSubmit}>
					{
						this.props.requestType !== 'point-of-sale' && (
							<div>
							{
								(userType === 'internal' || userType === 'broker' || userType === 'limited broker') ? (
									<div>
										<Select required name='purpose' value={info.purpose} onChange={this._handleName} label='Who is this request for?'>
											<option/>
											<option>Broker</option>
											<option>End User Customer</option>
											<option>Distributor</option>
											<option>Myself</option>
										</Select>
									</div>
								) : null
							}
							{
								info.purpose === 'Broker' ? (
									<div>
										<Input required type='text' onChange={this._handleChange} value={info.brokerName} name='brokerName' label='Name of broker:' placeholder='example: Key Impact'/>
										<Input required type='text' onChange={this._handleChange} value={info.brokerRep} name='brokerRep' label='Who is the broker rep?' placeholder='example: John Doe'/>
										<Input required type='text' onChange={this._handleChange} value={info.purposeValue} name='purposeValue' label='What is the purpose of this request?' placeholder='example: sales call to new account'/>
									</div>
								) : null
							}
							{
								info.purpose === 'Distributor' ? (
									<div>
										<Input required type='text' onChange={this._handleChange} value={info.distributorName} name='distributorName' label='Full name of distributor:' placeholder='example: Sysco/Raleigh, NC or USF/Lakeland, FL'/>
										<Input required type='text' onChange={this._handleChange} value={info.purposeValue} name='purposeValue' label='What is the purpose of this request?' placeholder='example: Presentation, Food Show, Sales Meeting, Ride-with, Other'/>
									</div>
								) : null
							}
							{
								info.purpose === 'End User Customer' || (userType !== 'internal' && userType !== 'broker' && userType !== 'limited broker') ? (
									<div>
										<Select required name='isCurrentCustomer' value={info.isCurrentCustomer} onChange={this._handleQuestion} label={((userType === 'internal' || userType === 'broker' || userType === 'limited broker') ? 'Is your customer' : 'Are you')+' currently buying David\'s Cookies products?'}>
											<option/>
											<option>Yes</option>
											<option>No</option>
										</Select>
										{
											info.isCurrentCustomer === 'Yes' ? (
												<Input required name='distributors' value={info.distributors} onChange={this._handleChange} type='text' label={'Which distributor(s) are '+((userType === 'internal' || userType === 'broker' || userType === 'limited broker') ? 'they' : 'you')+' currently buying David’s Cookies products from?'} placeholder='example: Sysco/Raleigh, NC or USF/Lakeland, FL'/>
											) : null
										}
										{
											this.state.modal ? (
												<Modal onClose={()=> this.setState({modal: false})}>
													<div className='MerchInfo_modal'>
														<p>If you are currently not buying any David’s Cookies products, please contact your local David’s representative.</p>
														<p> <a href='http://foodservice.davidscookies.com/contact'>Click here</a> to find your representative.</p>
													</div>
												</Modal>
											) : null
										}
										<Input required type='text' onChange={this._handleChange} value={info.purposeValue} name='purposeValue' label='What is the purpose of this request?' placeholder="example: new to David's Cookies brand, new product category purchased"/>
									</div>
								) : null
							}
							{
								info.purpose === 'Myself' ? (
									<div>
										<Input required type='text' onChange={this._handleChange} value={info.purposeValue} name='purposeValue' label='What is the purpose of this request?' placeholder="example: new to David's Cookies brand, new product category purchased"/>
									</div>
								) : null
							}
							</div>
						)
					}
					<p>All Merchandise / POS will ship only via UPS Ground. Please allow up to 5 business days for approval of this request and up to 15 business days after approval for delivery.</p>
					<Continue isMuted={!meetsInfoRequirements(this.props.requestType, info, userType)}/>
				</form>
			</div>
		);
	},
});
